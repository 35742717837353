import React, { useState } from 'react';
import { client } from '../../client'; // Assuming you're using this for other purposes
import { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';

function SearchBar() {
    const [searchQuery, setSearchQuery] = useState('');
    const [results, setResults] = useState([]);
    const languagekey = localStorage.getItem('locale');
    const [loading, setLoading] = useState(false);
  
    const handleQueryChange = (event) => {
        setSearchQuery(event.target.value);
    };

    const idToText = {
        whatIsRettSyndromewhatIsRettSyndrome: languagekey === 'fr' ? 'Qu\'est-ce que le syndrome de Rett ?' : 'What is Rett Syndrome ?',
        home: languagekey === 'fr' ? 'Accueil' : 'Home',
        rettStoriesrettStories: languagekey === 'fr' ? 'Histoires de Rett' : 'Rett Stories',
        rettResourcesrettResources: languagekey === 'fr' ? 'Ressources Rett' : 'Rett Resources',
        navigatingLifeWithRettnavigatingLifeWithRett: languagekey === 'fr' ? 'Naviguer avec Rett' : 'Navigating Life With Rett',
        aboutUsabout: languagekey === 'fr' ? 'À propos de nous' : 'About Us'
    };

    const idToUrl = {
        whatIsRettSyndromewhatIsRettSyndrome: languagekey === 'fr' ? 'what-is-rett-syndrome/fr/' : '/what-is-rett-syndrome',
        home: languagekey === 'fr' ? '/fr' : '/',
        rettStoriesrettStories: languagekey === 'fr' ? 'rett-stories/fr/' : '/rett-stories',
        rettResourcesrettResources: languagekey === 'fr' ? 'rett-resources/fr/' : '/rett-resources',
        navigatingLifeWithRettnavigatingLifeWithRett: languagekey === 'fr' ? 'navigating-life-with-rett/fr/' : '/navigating-life-with-rett',
        aboutUsabout: languagekey === 'fr' ? 'about-us/fr/' : '/about-us'
    };

    const redirectwithparem = (event) => {
        event.preventDefault(); // Prevent default form submission behavior
        
        if (languagekey === 'fr') {
            window.location.href = `/search/${searchQuery}/fr`; 
        } else {
            window.location.href = `/search/${searchQuery}`; 
        }
    };

    return (
        <div className="search-container">
            <div className="th_search">
                <form onSubmit={redirectwithparem}>
                    <input
                        type="text"
                        value={searchQuery}
                        onChange={handleQueryChange}
                        placeholder="Search for..."
                    />
                    <button type="submit" className="search-button">
                        <i className="fa-solid fa-magnifying-glass"></i>
                    </button>
                </form>
            </div>
        </div>
    );
}

export default SearchBar;
