import React from 'react'
import { client } from '../../../client';
import { useState } from 'react';
import { useEffect } from 'react';
import Headlines from './sections/Headlines';
import { useParams } from 'react-router-dom';

function StorieDetailes() {

    const languagekey = localStorage.getItem('locale');
    const [data, setData] = useState([]);
    const storieid = useParams();
    console.log(storieid.id)

    async function fetchEntry(entryId, locale) {
      try {
        const entry = await client.getEntry(entryId, {
          locale: locale // Pass the locale key here
        });
        setData(entry.fields)
      } catch (error) {
        console.error('Error fetching entry:', error);
      }
    }
  
useEffect(()=>{
    window.scrollTo(0, 0);
    fetchEntry(storieid.id, languagekey);
},[])
  return (
    <>
    <Headlines data={data}/>
      </>
  )
}   

export default StorieDetailes