import React from 'react'
import Canadianhuub from './Sections/Canadianhuub'
import { useState } from 'react'
import { useEffect } from 'react'
import { client } from '../../../client'
import Banner from '../containers/Banner'

function About() {
  const [isLoading, setLoading] = useState(true);
  const [data,setdata]=useState([])
  const languagekey = localStorage.getItem('locale');
  
  const getBannerdata = async (e)=>{
    try {
      const response = await client.getEntries({ content_type: 'bannerAbout', locale: languagekey})
      setdata(response.items);
      setLoading(false)
    }
    catch(error){
     
    }
  }
  useEffect(()=>{
    getBannerdata();
  },[])

  return (
    <>
    <Banner data={data}/>
    <Canadianhuub/>
    </>
    
  )
}

export default About