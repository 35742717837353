import React from 'react'
import Banner from '../containers/Banner'
import { useEffect } from 'react';
import { useState } from 'react';
import { client } from '../../../client';
import Cookiecutter from './Sections/Cookiecutter';
import Rettjourny from './Sections/Rettjourny';
import Timeline from './Sections/Timeline';

function Navigating() {

    const [isLoading, setLoading] = useState(true);
    const [data,setdata]=useState([])
    const languagekey = localStorage.getItem('locale');
    const getBannerdata = async (e)=>{
      try {
        const response = await client.getEntries({ content_type: 'bannerNavigating',locale: languagekey})
        setdata(response.items);
        setLoading(false)
      }
      catch(error){
        // console.log(error);
      }
    }
    useEffect(()=>{
      getBannerdata();
    },[])

  return (
    <>
    <Banner data={data}/>
    <Cookiecutter/>
    <section>
    <div class="rett_journey_section">
      <div class="container">
        <Rettjourny/>
        <Timeline/>
        </div>
        </div>
        </section>
    </>
  )
}

export default Navigating