import React from 'react'
import Banner from '../containers/Banner'
import { client } from '../../../client';
import { useState } from 'react';
import { useEffect } from 'react';
import Raredisorder from './Sections/Raredisorder';
import OccursWorldwide from './Sections/OccursWorldwide';
import OffenIncludes from './Sections/OffenIncludes';
import Refrences from './Sections/Refrences';

function Rettsyndrome() {
    const [isLoading, setLoading] = useState(true);
    const languagekey = localStorage.getItem('locale');
  const [data,setdata]=useState([])
  const getBannerdata = async (e)=>{
    try {
      const response = await client.getEntries({ content_type: 'syndromeBanner',locale: languagekey})
      setdata(response.items);
      console.log(response.item)
      setLoading(false)
    }
    catch(error){
      // console.log(error);
    }
  }
  useEffect(()=>{
    getBannerdata();
  },[])
  return (
    <div>
        <Banner data={data}/>
        <Raredisorder/>
        <OccursWorldwide/>
        <OffenIncludes/>
        <Refrences/>
    </div>
  )
}

export default Rettsyndrome