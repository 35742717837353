import React from 'react'
import { useState } from 'react'
import { useEffect } from 'react'
import { client } from '../../../client'
import Banner from '../containers/Banner'
import Signupcontent from './Sections/Signupcontent'
import Form from './Sections/Form'
function Contact() {


  const [isLoading, setLoading] = useState(true);
  const [data,setdata]=useState([])
  const languagekey = localStorage.getItem('locale');
  const getBannerdata = async (e)=>{
    try {
      const response = await client.getEntries({ content_type: 'bannerContact',locale: languagekey})
      setdata(response.items);
      setLoading(false)
    }
    catch(error){
      console.log(error);
    }
  }
  useEffect(()=>{
    getBannerdata();
  },[])

  return (
<>
<Banner data={data}/>

<Form/>
</>
  )
}

export default Contact