import React from 'react'

function Notfoundanything() {
  return (
    <div>
        <div className='notfound text-center m-5'>
        <h3>Nothing Found ....</h3>
            </div>
            
            </div>
  )
}

export default Notfoundanything