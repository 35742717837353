import React from 'react'
import Banner from '../containers/Banner'
import Notalone from './Sections/Notalone'
import Underconstructions from './Sections/Underconstructions'
import Tabs from './Sections/Tabs'
import { useState } from 'react'
import { useEffect } from 'react'
import { client } from '../../../client'
function Resources() {

  const [isLoading, setLoading] = useState(true);
  const [data,setdata]=useState([])
  const languagekey = localStorage.getItem('locale');
  const getBannerdata = async (e)=>{
    try {
      const response = await client.getEntries({ content_type: 'bannerResources',locale: languagekey})
      setdata(response.items);
      setLoading(false)
    }
    catch(error){
      // console.log(error);
    }
  }
  useEffect(()=>{
    getBannerdata();
  },[])

  return (
    <>
  <Banner data={data}/>
   <Notalone/>
   <Tabs/>
    </>
  )
}

export default Resources