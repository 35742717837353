import React, { useState, useEffect } from 'react';
import Banner from '../containers/Banner';
import Remarkable from './sections/Remarkable';
import Syndrome from './sections/Syndrome';
import Navigating from './sections/Navigating';
import { client } from '../../../client';
import Refrences from './sections/Refrences';
const contentful = require('contentful-management');

function Home() {

  const client2 = contentful.createClient({
    accessToken: 'CFPAT-2QPAQbSn_1mLQNoHW6sj9O4V1A2rzTD9-Yfls6w-MhM'
  });
  const languagekey = localStorage.getItem('locale');
  
  

  const [isLoading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [loadedComponents, setLoadedComponents] = useState(0);

  

  const totalComponents = 4; // Update this if you add or remove components

  const getBannerData = async () => {
    try {
      const response = await client.getEntries({ content_type: 'sliderHome',locale: languagekey});
      setData(response.items);
      setLoading(false);
    } catch (error) {
      // console.log(error);
    }
  };

  useEffect(() => {
    getBannerData();
  }, []);

  const handleComponentLoad = () => {
    setLoadedComponents(prev => prev + 1);
  };

  useEffect(() => {
    if (loadedComponents === totalComponents) {
      setLoading(false);
    }
  }, [loadedComponents]);

  if (isLoading) {
    return <div>Loading...</div>; 
  }

  return (
    <>
      <Banner data={data} onLoad={handleComponentLoad} />
      <Remarkable onLoad={handleComponentLoad} />
      <Syndrome onLoad={handleComponentLoad} />
      <Navigating onLoad={handleComponentLoad} />
      <Refrences/>
    </>
  );
}

export default Home;
