import React, { useEffect } from 'react'
import Banner from '../containers/Banner'
import { client } from '../../../client';
import { useState } from 'react';
import Everyperson from './sections/Everyperson';
import Underdevelopment from './sections/Underdevelopment';
import Testimonials from './sections/Testimonials';

function Retstories() {
    const [isLoading, setLoading] = useState(true);
  const [data,setdata]=useState([])
  const languagekey = localStorage.getItem('locale');
  const getBannerdata = async (e)=>{
    try {
      const response = await client.getEntries({ content_type: 'bannerRettStories',locale: languagekey})
      setdata(response.items);

      setLoading(false)
    }
    catch(error){
      // console.log(error);
    }
  }
  useEffect(()=>{
    getBannerdata();
  },[])
  return (
    <div>
      <Banner data={data}/>
 <Everyperson/>
<Underdevelopment/>
{/* <Testimonials/> */}

    </div>
  )
}

export default Retstories