import React from 'react'
import Newsletter from './Newsletter'
import { useEffect } from 'react'
import { useState } from 'react'
import { client } from '../../client'
import { Link, NavLink } from 'react-router-dom'
import Footerbottom from './Footerbottom'
import InjectScriptFromAPI from './InjectScriptFromAPI'

function Footer() {

  const [isLoading, setLoading] = useState(true);
  const [data,setdata]=useState([])
  const [data2,setdata2]=useState([])
  const [tag,settag]=useState('')
  const languagekey = localStorage.getItem('locale');
  const getfooterdata = async (e)=>{
    try {
      const response = await client.getEntries({ content_type: 'footer',locale: languagekey})
      setdata(response.items);
      setLoading(false);
    }
    catch(error){
      // console.log(error);
}  
  }
  const getheaderdata = async (e)=>{
    try {
      const response = await client.getEntries({ content_type: 'topNav',locale: languagekey})
      setdata2(response.items);
    }
    catch(error){
      // console.log(error);
} 
  }
  const getgtatag = async (e)=>{
    try {
      const response = await client.getEntries({ content_type: 'gtaTag',locale: languagekey})
      settag(response.items[0]?.fields?.scriptcodefull);
    }
    catch(error){
      // console.log(error);
} 
  }


  useEffect(()=>{
    getgtatag();
    getfooterdata();
    getheaderdata();
  },[])


  return (
    <>
   
    <Newsletter/>
    <footer>
    <div class="footer_section">
      <div class="container">
        <div class="footer_row d-flex align-items-center">
          <div class="footer_col1">
            <div class="footer_logo">
              <img src={data2[0]?.fields?.icon?.fields?.file?.url} alt="candalogo" className='img-fluid' />
              <p>{data2[0]?.fields?.heading}</p>
            </div>
          </div>
          <div class="footer_col2">
            <div class="footer_menu">
              {/* <h5>Links</h5> */}
              <ul>
              {data[0]?.fields?.links.map((datavalue,index)=>{
                return(
                  <li key={index}>
                <NavLink to={data[0]?.fields?.nameLinks[index]}>{datavalue}</NavLink>
              </li>
                )
              })}
            </ul>
            </div>
          </div>
          {/* <div class="footer_col3">
            <div class="footer_menu ss_menu pe-0">
              <h5 class="fw_400">Contact Us</h5>
              <a href="#" class="site_btn">info@rettlife.org</a>
            </div>
          </div> */}
        </div>
        <div class="row">
          <div class="col-12">
            <div class="copyright_sec d-flex">
              <p>{data[0]?.fields?.footerbottomheading}</p>
             
              <ul class="d-flex justify-content-center align-items-center">
            
                <li><Link to={data[0]?.fields?.privacypolicylink}>{data[0]?.fields?.privacypolicyy}</Link></li>
                <li><Link to={data[0]?.fields?.termsconditionlink}>{data[0]?.fields?.termsandconditionn}</Link></li>
               
              </ul>
            
            </div>
            <div className='copyrighbe' style={{fontSize: "15px",  color: "white"}}>
            <p>{data[0]?.fields?.code}</p>
            </div>
           
          </div> 
        </div>
      </div>
    </div>
  </footer>
  <InjectScriptFromAPI datacode={tag}/>
    </>
  

  )
}

export default Footer