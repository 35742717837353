import Footer from "./components/common/Footer";
import Header from "./components/common/Header";
import Home from "./components/pages/Home/Home";
import { useEffect } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Retstories from "./components/pages/Rettstories/Retstories";
import Rettsyndrome from "./components/pages/Syndrome/Rettsyndrome";
import Navigating from "./components/pages/Navigating/Navigating";
import Resources from './components/pages/Resources/Resources'
import About from "./components/pages/About/About";
import Contact from "./components/pages/Contact/Contact";
import Rettstoriesdetails from './components/pages/Rettstoriesdetails/StorieDetaile'
import Search from "./components/pages/Search/Search";


function App() {



  return (
    <>
     <BrowserRouter >
        <Header />
        <Routes >
          <Route path="/" Component={() => <Home />} />
          <Route path="/rett-stories" Component={() => <Retstories />} />
          <Route path="*" Component={()=> <>Not Found</>}/>
          <Route path="/what-is-rett-syndrome" Component={() => <Rettsyndrome/>} />
          <Route path="/navigating-life-with-rett" Component={()=> <Navigating/>} />
          <Route path="/rett-resources" Component={()=> <Resources/>} />
          <Route path="/about-us" Component={()=> <About/>} />
          <Route path="/contact-us" Component={()=> <Contact/>} />
          <Route path="/stories-details/:id" Component={()=> <Rettstoriesdetails/>} />
          <Route path="/search/:id" Component={()=> <Search/>} />

          {/* french routes */}


          <Route path="/fr" Component={() => <Home />} />
          <Route path="/rett-stories/fr" Component={() => <Retstories />} />
          <Route path="*" Component={()=> <>Not Found</>}/>
          <Route path="/what-is-rett-syndrome/fr" Component={() => <Rettsyndrome/>} />
          <Route path="/navigating-life-with-rett/fr" Component={()=> <Navigating/>} />
          <Route path="/rett-resources/fr" Component={()=> <Resources/>} />
          <Route path="/about-us/fr" Component={()=> <About/>} />
          <Route path="/contact-us/fr" Component={()=> <Contact/>} />
          <Route path="/stories-details/:id/fr" Component={()=> <Rettstoriesdetails/>} />
          <Route path="/search/:id/fr" Component={()=> <Search/>} />
        </Routes>
        <Footer/>
      </BrowserRouter>
     
    </>
  );
}
export default App;
