import React, { useEffect, useState } from 'react';
import { useLocation, Link } from 'react-router-dom';

function Banner(props) {
  const propsdata = props.data;
  const [isLoading, setLoading] = useState(true);
  const [data, setData] = useState(props.data);
  const [link, setLink] = useState("/");
  const location = useLocation();
  const languagekey = localStorage.getItem('locale') || 'en';
  const [homelang,sethomelang] =useState('Home');

  useEffect(() => {
    setData(props.data);
    setLoading(false);
    window.scrollTo(0, 0);
    if(languagekey=='fr'){
      sethomelang("page d'accueil")
    }
  }, [propsdata, location.pathname]);

  useEffect(() => {
    if (languagekey === 'fr') {
      setLink("/fr");
    } else {
      setLink('/');
    }
  }, [languagekey]);

  const translations = {
    en: {
      aboutUs: 'About us' ,
      contactUs: 'Contact us',
      rettStories: 'Rett Stories',
      rettSyndrome: 'What is Rett Syndrome ?',
      navigatingLifeWithRett: 'Navigating Life with Rett',
      resources: 'Rett Resources',
      storyDetails: 'Story Details',
    },
    fr: {
      aboutUs: 'À propos de nous',
      contactUs: 'Pour nous joindre',
      rettStories: 'Histoires de Rett',
      rettSyndrome: 'Qu’est-ce que le syndrome de Rett?',
      navigatingLifeWithRett: 'Se frayer un chemin dans la vie avec Rett',
      resources: 'Ressources pour le syndrome de Rett',
      storyDetails: 'Détails de l\'histoire',
    },
  };

  const getBreadcrumbName = (pathname) => {
    const translation = translations[languagekey] || translations['en'];
    switch (pathname) {
      case '/about-us':
      case '/about-us/fr':
        return translation.aboutUs;
      case '/contact-us':
      case '/contact-us/fr':
        return translation.contactUs;
      case '/rett-stories':
      case '/rett-stories/fr':
        return translation.rettStories;
      case '/what-is-rett-syndrome':
      case '/what-is-rett-syndrome/fr':
        return translation.rettSyndrome;
      case '/navigating-life-with-rett':
      case '/navigating-life-with-rett/fr':
        return translation.navigatingLifeWithRett;
      case '/rett-resources':
      case '/rett-resources/fr':
        return translation.resources;
      case '/stories-details':
      case '/stories-details/fr':
        return translation.storyDetails;
      default:
        return '';
    }
  };

  const breadcrumbName = getBreadcrumbName(location.pathname);

  return (
    <section>
      <div className="banner_section">
        <div className="banner_slide">
          <img src={isLoading ? "images/loading-7528_128.gif" : data[0]?.fields?.bgImage?.fields?.file?.url} alt={data[0]?.fields?.bgImage?.fields?.description} className="img-fluid w-100" />
          <div className="banner_outer">
            <div className="container">
              <div className="heading_sec">
                <h1><span>{isLoading ? "loading..." : data[0]?.fields?.one}</span> {data[0]?.fields?.two} <span>{data[0]?.fields?.three}</span></h1>
                <p>{data[0]?.fields?.four}</p>
              </div>
            </div>
          </div>
          <div className="banner_outer banner_breadcrumb">
            <div className="container">
              <nav className="breadcrumb_block" aria-label="breadcrumb">
                <ol className="breadcrumb">
                  {breadcrumbName && (
                    <li className="breadcrumb-item">
                      <Link to={link}>{homelang}</Link>
                      <i className="fa-solid fa-chevron-right"></i>
                    </li>
                  )}
                  {breadcrumbName && (
                    <li className="breadcrumb-item active" aria-current="page">{breadcrumbName}</li>
                  )}
                </ol>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Banner;
